/*** Theme colors:
  textwhite: '#E5E5E5',
  textblack: '#212427',
  black: '#121212',
  darkgrey: '#363636',
  grey: '#EBEBEB',
  white: '#FFFFFF',
  green: '#548687',
  red: '#B0413E'
***/

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 2.1rem;
  height: 1.6rem;
  right: 2.5rem;
  top: 1.25rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #548687;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  position: fixed;
  height: 24px;
  width: 24px;
  top: 1.5rem !important;
  right: 2.5rem !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
  visibility: hidden;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(84, 134, 135, 0.8);
  padding: 2.5em 1em 0;
  font-size: 1.5rem;
  font-weight: 300;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 3px 21px 2px 21px;
  text-decoration-line: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.2) !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #212427;
  background-color: #ebebeb;
  border-radius: 20px;
  padding: 6px 23px;
  margin-bottom: 15px;
  text-align: left;
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem;
}

.bm-item:hover {
  cursor: pointer;
}
