@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

@layer base {
  body {
    font-family: 'Raleway';
    @apply antialiased;
    @apply text-base;
    @apply font-normal;
    @apply bg-white;
  }

  .body {
    /** needed for timeline **/
    font-family: 'Raleway';
    @apply antialiased;
    @apply text-base;
    @apply font-normal;
  }

  p {
    @apply whitespace-pre-line;
  }

  a {
    @apply underline decoration-solid;
    @apply underline-offset-2;
    @apply decoration-green;
  }

  .h1-light {
    font-family: 'Raleway';
    @apply text-4xl;
    @apply md:text-5xl;
    @apply font-light;
    @apply mb-3;
    @apply text-center;
    @apply antialiased;
    @apply text-red;
  }

  .h1-dark {
    font-family: 'Raleway';
    @apply text-4xl;
    @apply md:text-5xl;
    @apply font-light;
    @apply mb-3;
    @apply text-center;
    @apply antialiased;
    @apply text-green;
  }

  .h2-light {
    font-family: 'Raleway';
    @apply mb-4;
    @apply text-lg;
    @apply text-justify;
    @apply antialiased;
    @apply whitespace-pre-line;
    @apply text-red;
  }

  .h2-dark {
    font-family: 'Raleway';
    @apply mb-4;
    @apply text-lg;
    @apply text-justify;
    @apply antialiased;
    @apply whitespace-pre-line;
    @apply text-textgreen;
  }

  .h3-dark {
    font-family: 'Raleway';
    @apply mb-4;
    @apply text-base;
    @apply font-normal;
    @apply text-justify;
    @apply antialiased;
    @apply whitespace-pre-line;
    @apply text-textgreen;
  }

  .h3-light {
    font-family: 'Raleway';
    @apply mb-4;
    @apply text-base;
    @apply font-normal;
    @apply text-justify;
    @apply antialiased;
    @apply whitespace-pre-line;
    @apply text-red;
  }
}
